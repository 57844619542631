.hevara-delete-account {
  .settings-group {
    max-width: 558px;
    margin: auto;
    margin-bottom: 100px;

    .hevara-navigation-row {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}