.hevara-chip.item-list-chip-desktop {
  display: none;
  
  @media (min-width: 768px) {
    display: inline-block;
  }
}

.hevara-chip.item-list-chip-mobile {
  display: inline-block;
  
  @media (min-width: 768px) {
    display: none;
  }
}