.hevara-activate {
  @media (min-width: 992px) {
    padding: 50px 0px;
  }
  
  .activate-box {
    max-width: 730px;

    @media (min-width: 768px) {
      padding: 0px 95px;
    }
  }

  .placeholder {
    width: 150px !important;
    margin-bottom: 20px;
  }

  .empty-box {
    padding: 1.5rem; // Adjust as needed
    // Add more styles as needed
  }
}