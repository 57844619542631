/* LoadingBackdrop.css */

.loading-backdrop {
  background-color: rgba(239, 242, 247, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  .hevara-spinner {
    margin: inherit !important;
  }
  .hevara-loading-backdrop-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 558px;
    @media (max-width: 574px) {
      max-width: 100%;
      padding: 8px;
    }
  }
}

.hevara-loading-backdrop-title, .hevara-loading-backdrop-description {
  text-align: center;
  margin-top: 12px;
}

.hevara-loading-backdrop-title {
  font-weight: bolder;
  font-size: medium;
}

.hevara-loading-backdrop-description {
  font-weight: 500;
  font-size: small;
}

.hevara-loading-backdrop-children-container {
  margin-top: 12px;
}
