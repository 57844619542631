.hevara-web-container {
  padding: 0px 16px;
  width: 100%;
  max-width: 1140px;
  margin: auto;

  @media (min-width: 576px) {
    padding: 0px 24px;
  }
  
  @media (min-width: 1440px) {
    width: 100%;
    padding: 0px;
    margin: 0px auto;
  }
}