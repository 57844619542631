.onboarding-user-ph {
  display: none;

  @media (min-width: 992px) {
    display: flex;
    position: sticky;
    padding-top: 80px;
  }

  .onboarding-user-ph-image {
    .hevara-empty-state-image {
      max-height: 664px;
      max-width: 664px;
      width: 100%;
      height: 100%;
    }
  }
}
.page-padding {
  padding-bottom: 100px;
}