.hevara-data-amount {
  &.amount-no-resize {
    .hevara-data-amount-value {
      font-size: 32px;
      line-height: 40px;
    }
  }
  &.currency-resize {
    .hevara-data-amount-currency {
      font-size: 14px;
      line-height: 20px;

      @media (max-width: 576px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}