$gray-300: #f6f6f6;
$light: $gray-300;

.permission-box {
  border: 2px solid $light;
  padding: 12px;
  border-radius: 16px;
  margin-bottom: 4px;
}

.secondary-permission-box {
  margin-left: 32px;
}

.hevara-transaction-data {
  width: 100%;

  .tx-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    @media (min-width: 992px) {
      grid-template-columns: 2fr 1fr;
    }

    .detail-grid {
      display: grid;
      grid-row-gap: 16px;
      grid-template-columns: minmax(0, 1fr);

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

.hevara-wallet-list {
  width: 100%;
}

.title-container {
  padding-left: 8px;
  flex: 1;

  @media (min-width: 576px) {
    display: flex;
    flex-direction: row;
    flex-basis: auto;
    justify-content: space-between;
    align-items: center;
  }
  .wallet-totals {
    flex-direction: column;;
    align-items: flex-start;

    @media (min-width: 576px) {
      flex-direction: row;
      align-items: flex-end;
    }
    .wallet-total-item {
      margin-bottom: 24px;

      @media (min-width: 576px) {
        margin-bottom: 0px;
        margin-right: 48px;
      }
    }
  }
}