.hevara-onboarding-user {
  display: grid;
  padding-bottom: 100px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  .onboarding-user-ph {
    display: none;
    
    @media (min-width: 992px) {
      display: flex;
      position: sticky;
      padding-top: 80px;
    }
    .onboarding-user-ph-image {
      .hevara-empty-state-image {
        max-height: 364px;
        max-width: 364px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .hevara-text-field {
    width: 100%;
  }
  .small-field {
    @media (min-width: 768px) {
      width: 50%;
    }
  }

.autocomplete-container {
  position: relative;
  width: 600px;
  /* Set the width as needed */
}

.location-input {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1px;
}

.suggestion-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
}

.suggestion-container div {
  padding: 10px;
  cursor: pointer;
}

.suggestion-container div:hover {
  background-color: #f0f0f0;
}
}