$gray-300: #f6f6f6;
$light: $gray-300;


.hevara-notification-detail {
  width: 100%;

  .tx-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    @media (min-width: 992px) {
      grid-template-columns: 2fr 1fr;
    }

    &>* {
      order: 1;
    }

    .detail-grid {
      display: grid;
      grid-row-gap: 16px;
      grid-template-columns: minmax(0, 1fr);

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
    }

    .tx-avatar {
      justify-self: start;
      order: 0;

      @media (min-width: 992px) {
        order: 2;
        justify-self: end;
      }

      .avatar-size {
        width: 170px;
        height: 170px;
        min-width: 170px;
        min-height: 170px;
        margin-left: auto;

        .hevara-avatar-image {
          width: 170px;
          height: 170px;
          min-width: 170px;
          min-height: 170px;
        }

        .hevara-avatar-text {
          font-size: 32px;
        }
      }
    }
  }

  .settings-group {
    max-width: 558px;
    margin: auto;
    margin-bottom: 100px;
  }

  .permission-box {
    border: 2px solid $light;
    padding: 12px;
    border-radius: 16px;
    margin-bottom: 4px;
  }
}

