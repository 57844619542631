@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hevara-wallet-grid-list-item {
  .hevara-table-row-main-info,
  .hevara-table-row-main-info-grid {
    & > :last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .hevara-table-row-main-info-avatar {
    flex-shrink: 0;
  }
  .hevara-table-row-main-info-title {
    @include truncate;
  }
}

.hevara-wallet-grid-item {
  .hevara-table-row-amount-title,
  .hevara-table-row-amount-subtitle {
    font-weight: bold;
  }
  .wallet-chips-layout {
    display: flex;
    .hevara-wallet-chip:not(:first-child) {
      margin-left: 4px;
    }
  }
}

.hevara-wallet-list-item {
  .hevara-table-row-wrapper {
    display: grid;
    grid-template-columns: auto max-content;

    @media (min-width: 1200px) {
      grid-template-columns: 2fr 1fr 1fr;
    }
    .status-desktop {
      justify-content: flex-start;
    }
    .wallet-chips-layout {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }
    .hevara-wallet-chip:not(:first-child) {
      margin-top: 4px;

      @media (min-width: 768px) {
        margin-top: 0px;
        margin-left: 4px;
      }
    }
  }
  .balance {
    max-width: 100%;
    align-items: flex-end;
    flex-direction: column;

    @media (min-width: 1200px) {
      max-width: 100%;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
    }
    .status-mobile {
      display: flex;

      @media (min-width: 1200px) {
        display: none;
      }
    }
  }
  .hash_chip {
    .hevara-table-row-amount-subtitle {
      display: none;

      @media (min-width: 1200px) {
        display: flex;
      }
    }
  }
}