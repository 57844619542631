.autocomplete-container {
  position: relative;
  width: 100%;
  /* Set the width as needed */
}

.location-input {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1px;
}

.suggestion-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
}

.suggestion-container div {
  padding: 10px;
  cursor: pointer;
}

.suggestion-container div:hover {
  background-color: #f0f0f0;
}

.hevara-user-data {
  width: 100%;

  .tx-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    @media (min-width: 992px) {
      grid-template-columns: 2fr 1fr;
    }

    .detail-grid {
      display: grid;
      grid-row-gap: 16px;
      grid-template-columns: minmax(0, 1fr);

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}