@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hevara-new-product-catalogue {
  .catalogue-group {
    max-width: 558px;
    margin: auto;
  }
  .form-errors {
    height: 24px;
  }
  .input-box, .hevara-info {
    margin-bottom: 16px;
  }
  .hevara-selector-group, .hevara-selector-data, .hevara-selector-name, .hevara-selector-description {
    @include truncate;
    width: 100%;
  }
}